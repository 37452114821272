import React, { useState, useEffect, useRef, useMemo } from "react";
import styled from "styled-components";
import gsap from "gsap";
import { navigate } from "gatsby";
import loadable from '@loadable/component'
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";

import media from "@styles/media";
import colors from "@styles/colors";
import text from "@styles/text";

import useMedia from "@hooks/useMedia";

const TextAnimation = loadable(() => import("@components/TextAnimation"));
const PrimaryButton = loadable(() => import("@components/PrimaryButton"));

type props = {
  executives: any;
};

const Executives: React.FC<props> = ({ executives }) => {
  const [triggerTitle, setTriggerTitle] = useState(false);

  const wrapperRef = useRef(null);

  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: wrapperRef.current,
        start: "top-=20% top",
      },
    });
    tl.call(setTriggerTitle, [true], 0);
    tl.to(
      ".exec",
      {
        opacity: 1,
        duration: 0.8,
        stagger: 0.2,
      },
      0.8
    );

    return () => {
      tl.kill();
    };
    
  }, [wrapperRef, setTriggerTitle]);

  const titleTextArray = useMedia(
    [() => "Meet our", () => "executive team"],
    [() => "Meet our", () => "executive team"],
    [() => "Meet our", () => "executive team"],
    [() => "Meet our", () => "executive", () => "team"]
  );

  const sortedExecs = useMemo(() => executives.sort((a, b) => {
    if (a.order > b.order) {
      return 1;
    } else if (a.order < b.order) {
      return -1;
    } else {
      return 0;
    }
  }), [executives])

  const execs = useMemo(() => sortedExecs.map((item, index) => {
    let slug = item.fullName.replace(/\s/, "-").toLowerCase();

    return (
      <Card className="exec" key={index}>
        <StyledImg>
          <GatsbyImage
            loading="lazy"
            objectFit="cover"
            alt={item.fullName}
            image={item.headshot.gatsbyImageData}
          />
        </StyledImg>
        <Name>{item.fullName}</Name>
        <Role>{item.title}</Role>
        <HR />
        <PrimaryButton
          padding
          text="Read Bio"
          onClick={() => {
            navigate(`/about/${slug}`);
          }}
        />
      </Card>
    );
  }), [sortedExecs])

  return (
    <Wrapper ref={wrapperRef} id="executives">
      <Accent>
        <StaticImage
          src={'../../images/png/accentBG.png'} 
          alt="gradient"
          placeholder="blurred"
          style={{width: '100%', height: '100%'}}
        />
      </Accent>
      <Title>
        <TextAnimation
          textArray={titleTextArray || []}
          trigger={triggerTitle}
          className="about-executives"
          height={useMedia("4.861vw", "4.861vw", "8.383vw", "12.800vw")}
        />
      </Title>
      <CardContainer>{execs}</CardContainer>
    </Wrapper>
  );
};

export default Executives;

const Wrapper = styled.section`
  background-color: ${colors.black};
  position: relative;

  ${media.fullWidth} {
    padding-top: 12.5vw;
    padding-left: 11.319vw;
    padding-bottom: 11.875vw;
  }

  ${media.desktop} {
    padding-top: 12.5vw;
    padding-left: 11.319vw;
    padding-bottom: 11.875vw;
  }

  ${media.tablet} {
    padding-top: 15.449vw;
    padding-left: 13.533vw;
    padding-bottom: 12.455vw;
  }

  ${media.mobile} {
    padding-top: 29.067vw;
    padding-left: 6.667vw;
    padding-bottom: 45.867vw;
  }
`;

const Title = styled.h2`
  color: ${colors.culturedWhite60};
  display: flex;
  flex-direction: column;

  ${media.fullWidth} {
    ${text.desktopLHeading}
    width: 66.736vw;
    margin-bottom: 6.944vw;
  }

  ${media.desktop} {
    ${text.desktopLHeading}
    width: 66.736vw;
    margin-bottom: 6.944vw;
  }

  ${media.tablet} {
    ${text.tabletLHeading}
    width: 66.736vw;
    margin-bottom: 13.054vw;
  }

  ${media.mobile} {
    ${text.mobileSHeading}
    width: 86.667vw;
    margin-bottom: 21.333vw;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  opacity: 0;

  ${media.fullWidth} {
    width: 23.403vw;
    margin-right: 7.917vw;
    margin-bottom: 6.944vw;
  }

  ${media.desktop} {
    width: 23.403vw;
    margin-right: 7.917vw;
    margin-bottom: 6.944vw;
  }

  ${media.tablet} {
    width: 28.144vw;
    margin-right: 9.341vw;
    margin-bottom: 10.778vw;
  }

  ${media.mobile} {
    width: 86.933vw;
    margin-right: 0vw;
    margin-bottom: 26.667vw;
  }
`;

const Name = styled.h3`
  color: ${colors.white};

  ${media.fullWidth} {
    ${text.desktopSHeading}
    margin-bottom: 0.694vw;
  }

  ${media.desktop} {
    ${text.desktopSHeading}
    margin-bottom: 0.694vw;
  }

  ${media.tablet} {
    ${text.tabletXSHeading}
    margin-bottom: 0.958vw;
  }

  ${media.mobile} {
    ${text.mobileSHeading}
    margin-bottom: 2.667vw;
  }
`;

const StyledImg = styled.div`
  width: 100%;

  ${media.fullWidth} {
    height: 23.403vw;
    margin-bottom: 2.083vw;
  }

  ${media.desktop} {
    height: 23.403vw;
    margin-bottom: 2.083vw;
  }

  ${media.tablet} {
    height: 28.144vw;
    margin-bottom: 3.713vw;
  }

  ${media.mobile} {
    height: 86.667vw;
    margin-bottom: 10.667vw;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;

  ${media.fullWidth} {
    padding-left: 15.694vw;
  }

  ${media.desktop} {
    padding-left: 15.694vw;
  }

  ${media.tablet} {
    padding-left: 0vw;
  }

  ${media.mobile} {
    flex-direction: column;
    padding-left: 0vw;
  }
`;

const Role = styled.p`
  color: ${colors.keppel100};

  ${media.fullWidth} {
    ${text.desktopBodyCopy1}
    margin-bottom: 2.778vw;
  }

  ${media.desktop} {
    ${text.desktopBodyCopy1}
    margin-bottom: 2.778vw;
  }

  ${media.tablet} {
    ${text.tabletSmallBody}
    margin-bottom: 1.317vw;
    height: 4.79vw;
  }

  ${media.mobile} {
    ${text.mobileBodyCopy1}
    margin-bottom: 10.667vw;
    height: 7.2vw;
  }
`;

const HR = styled.hr`
  width: 100%;
  height: 1px;
  background-color: ${colors.white};
  border: none;
  margin: 0px;

  ${media.fullWidth} {
    margin-bottom: 1.778vw;
  }

  ${media.desktop} {
    margin-bottom: 1.778vw;
  }

  ${media.tablet} {
    margin-bottom: 3.473vw;
  }

  ${media.mobile} {
    margin-bottom: 10.667vw;
  }
`;

const Accent = styled.div`
  position: absolute;

  ${media.fullWidth} {
    transform: rotate(-67deg) scale(1, -1);
    width: 61.181vw;
    height: 61.181vw;
    top: -12vw;
    right: -8vw;
  }

  ${media.desktop} {
    transform: rotate(-67deg) scale(1, -1);
    width: 61.181vw;
    height: 61.181vw;
    top: -12vw;
    right: -8vw;
  }

  ${media.tablet} {
    transform: rotate(-25deg) scale(1, -1);
    width: 84.91vw;
    height: 84.91vw;
    top: -12vw;
    right: -23vw;
  }
`;
